.my-sider {
    min-height: 100vh;
}

.my-content {
    padding: 45px;
    padding-top: 25px;
}

.ant-select-dropdown {
    left: 0px !important;
    top: 32px !important;
    position: absolute !important;
}

.ant-select-tree {
    left: 0px !important;
    top: 32px !important;
}